import PaginationData from "@/models/general/PaginationData";
import EmployeeGrant from "./EmployeeGrant";
import EmployeeGrantsFilter from "./EmployeeGrantsFilter";

export default class EmployeeGrants {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.employeeGrantsData = [];
    this.employeeGrant = new EmployeeGrant();
    this.filterData = new EmployeeGrantsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.employeesGrantsPagination);
    this.employeeGrantsData =
      data.employeesGrantsPagination.employeesGrantsData;
  }
}
