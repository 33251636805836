<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div
      v-if="
        employeeGrants.employeeGrantsData != undefined &&
        employeeGrants.employeeGrantsData.length != 0
      "
    >
      <EmployeeGrantsTable
        :employeeGrantsData="employeeGrants.employeeGrantsData"
        :employeeGrantData="employeeGrants.employeeGrant"
        v-on:setEmployeeGrantData="
          employeeGrants.employeeGrant.fillData($event)
        "
        :pagination="employeeGrants.pagination"
      />
      <EmployeeGrantInfo :employeeGrantData="employeeGrants.employeeGrant" />
      <EmployeeGrantCancel
        :employeeGrantData="employeeGrants.employeeGrant"
        v-on:refresh="getAllEmployeeGrants()"
      />
      <Pagination
        v-if="!isLoading"
        :paginationData="employeeGrants.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <EmployeeGrantFloatBtns
      :theFilterData="employeeGrants.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeGrantsTable from "@/components/employees/employeeGrants/EmployeeGrantsTable.vue";
import EmployeeGrantInfo from "@/components/employees/employeeGrants/EmployeeGrantInfo.vue";
import EmployeeGrantCancel from "@/components/employees/employeeGrants/EmployeeGrantCancel.vue";
import EmployeeGrantFloatBtns from "@/components/employees/employeeGrants/EmployeeGrantFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import EmployeeGrants from "@/models/employees/employeeGrants/EmployeeGrants";

export default {
  name: "EmployeeGrants",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeGrantsTable,
    EmployeeGrantInfo,
    EmployeeGrantCancel,
    EmployeeGrantFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      employeeGrantToken: "",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeGrants: new EmployeeGrants(),
    };
  },
  methods: {
    changePage(page) {
      this.employeeGrants.pagination.selfPage = page;
      this.getAllEmployeeGrants();
    },
    search(data) {
      this.employeeGrants.filterData.fillData(data);
      this.employeeGrants.employeeGrantsData = [];
      this.getAllEmployeeGrants();
    },
    async getAllEmployeeGrants() {
      this.isLoading = true;
      try {
        const response =
          await this.employeeGrants.employeeGrant.getAllEmployeeGrants(
            this.language,
            this.userAuthorizeToken,
            this.employeeGrants.pagination,
            this.employeeGrants.filterData
          );

        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeeGrants.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeeGrants.employeeGrantsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeeGrants.employeeGrantsData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeeGrants.employeeGrantsData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.employeeGrants.employeeGrantsData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.currentTab && this.employeeToken) {
      this.employeeGrants.filterData.employeeToken = this.employeeToken;
    }
    this.getAllEmployeeGrants();
  },
};
</script>
