<template>
  <div class="fab-container">
    <div class="fab-parent fab-icon-holder">
      <i class="fa fa-chevron-up"></i>
    </div>
    <ul class="fab-options">
      <li @click="routeTo()">
        <span class="fab-label">
          {{ $t("employeeGrants.add") }}
        </span>
        <div class="fab-icon-holder">
          <img src="@/assets/images/plus.svg" />
        </div>
      </li>

      <li>
        <span class="fab-label" @click="open">
          {{ $t("filter") }}
        </span>
        <div class="fab-icon-holder" @click="open">
          <img src="@/assets/images/filter.svg" />
        </div>
      </li>
    </ul>

    <vue-bottom-sheet ref="EmployeeBottomSheet">
      <div class="row">
        <CustomSelectBox
          :className="'col-md-12'"
          :id="'employeeToken'"
          :value="filterData.employeeToken"
          :options="employeeTokenOptions"
          v-on:changeValue="filterData.employeeToken = $event"
          :title="$t('selectEmployee')"
          :imgName="'employees.svg'"
        />
        <CustomInput
          :className="'col-md-12'"
          :id="'textSearch'"
          :value="filterData.textSearch"
          :title="$t('search')"
          :imgName="'search.svg'"
          v-on:changeValue="filterData.textSearch = $event"
        />
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="search"
        >
          {{ $t("search") }}
        </button>
        <button class="btn btn-cancel" @click.prevent="close">
          {{ $t("cancel") }}
        </button>
      </div>
    </vue-bottom-sheet>
  </div>
</template>

<script>
import privilegeMixin from "@/utils/privilege-mixin";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import { STATUS } from "@/utils/constants";
import axios from "axios";

export default {
  name: "EmployeeGrantFloatBtns",
  mixins: [privilegeMixin],
  components: {
    CustomInput,
    CustomSelectBox,
  },
  props: {
    theFilterData: {
      type: Object,
    },
  },
  data() {
    return {
      currentTab: "",
      filterData: this.theFilterData,
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeTokenOptions: [],
    };
  },
  methods: {
    open() {
      this.$refs.EmployeeBottomSheet.open();
    },
    close() {
      this.$refs.EmployeeBottomSheet.close();
    },
    search() {
      this.close();
      this.$emit("search", this.filterData);
    },
    getPageInfo() {
      this.currentTab = this.$route.meta.currentTab || "";
    },
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await axios.get(
          `/Employees/GetDialogOfEmployees?language=${this.language}&userAuthorizeToken=${this.userAuthorizeToken}`
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    routeTo() {
      if (this.currentTab) {
        this.$router
          .push({
            name: "EmployeeGrantAddTab",
            params: {
              employeeToken:
                this.filterData.employeeToken ||
                this.$store.getters.employeeToken,
            },
          })
          .catch(() => {});
      } else {
        this.$router
          .push({
            name: "EmployeeGrantAdd",
            params: {
              employeeToken:
                this.filterData.employeeToken ||
                this.$store.getters.employeeToken,
            },
          })
          .catch(() => {});
      }
    },
  },
  created() {
    this.getPageInfo();
    this.getDialogOfEmployees();
  },
};
</script>
