<template>
  <b-modal id="EmployeeGrantInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/grants.svg" class="icon-lg" />
        {{ $t("employeeGrants.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.employeeNameCurrent"
        :title="$t('employeeGrants.employeeName')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.grantTypeNameCurrent"
        :title="$t('employeeGrants.grantType')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.grantNameCurrent"
        :title="$t('employeeGrants.name')"
        :imgName="'grants.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.mathematicalTypeNameCurrent"
        :title="$t('employeeGrants.mathematicalType')"
        :imgName="'type.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.grantReasonAr"
        :title="$t('employeeGrants.reasonAr')"
        :imgName="'reason.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.grantReasonEn"
        :title="$t('employeeGrants.reasonEn')"
        :imgName="'reason.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.grantReasonUnd"
        :title="$t('employeeGrants.reasonUnd')"
        :imgName="'reason.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          employeeGrantData.hourRateFactorActivationStatus
            ? $t('activeOfferStatus')
            : $t('disActiveOfferStatus')
        "
        :title="$t('employeeGrants.hourRateFactorActivationStatus')"
        :imgName="'checkmark.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.hourRateFactor"
        :title="$t('employeeGrants.hourRateFactor')"
        :imgName="'hours.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.grantValue"
        :title="$t('employeeGrants.value')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.employeeGrantDate"
        :title="$t('employeeGrants.date')"
        :imgName="'date.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="timeToLang(employeeGrantData.actionDateTime)"
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.employeeMakeActionNameCurrent"
        :title="$t('userMakeAction')"
        :imgName="'man.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="employeeGrantData.employeeGrantNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { setDataMultiLang, timeToLang } from "@/utils/functions";

export default {
  name: "EmployeeGrantInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["employeeGrantData"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
};
</script>

<style scoped lang="scss"></style>
