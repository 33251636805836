<template>
  <div v-if="employeeGrantsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("employeeGrants.employeeName") }}</th>
            <th>{{ $t("employeeGrants.grantType") }}</th>
            <th>{{ $t("employeeGrants.name") }}</th>
            <th>{{ $t("employeeGrants.reason") }}</th>
            <th>{{ $t("employeeGrants.value") }}</th>
            <th>{{ $t("employeeGrants.hourRateFactor") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("actionDateTime") }}</th>
            <th>{{ $t("info") }}</th>
            <th>{{ $t("cancel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(employeeGrant, index) in employeeGrantsData"
            :key="employeeGrant.employeeGrantToken"
          >
            <td>{{ ++index + pagination.currentIndex }}</td>
            <td>{{ isDataExist(employeeGrant.employeeNameCurrent) }}</td>
            <td>{{ isDataExist(employeeGrant.grantTypeNameCurrent) }}</td>
            <td>{{ isDataExist(employeeGrant.grantNameCurrent) }}</td>
            <td>{{ isDataExist(employeeGrant.grantReasonCurrent) }}</td>
            <td>{{ isDataExist(employeeGrant.grantValue) }}</td>
            <td>{{ isDataExist(employeeGrant.hourRateFactor) }}</td>
            <td>
              {{ isDataExist(employeeGrant.employeeGrantNotes) }}
            </td>
            <td>{{ isDataExist(timeToLang(employeeGrant.actionDateTime)) }}</td>
            <td>
              <button
                v-b-modal.EmployeeGrantInfo
                class="btn p-0"
                :title="$t('info')"
                @click="setEmployeeGrantData(employeeGrant)"
              >
                <img src="@/assets/images/info.svg" class="icon-lg" />
              </button>
            </td>

            <td>
              <button
                v-b-modal.EmployeeGrantCancel
                class="btn p-0"
                :title="$t('cancel')"
                @click="setEmployeeGrantData(employeeGrant)"
              >
                <img src="@/assets/images/cancel.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { timeToLang, isDataExist } from "@/utils/functions";
import privilegeMixin from "@/utils/privilege-mixin";

export default {
  name: "EmployeeGrantsTable",
  mixins: [privilegeMixin],
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["employeeGrantsData", "employeeGrantData", "pagination"],
  methods: {
    timeToLang,
    isDataExist,
    setEmployeeGrantData(employeeGrantData) {
      this.$emit("setEmployeeGrantData", employeeGrantData);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
